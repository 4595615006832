<template>
  <div class="container">
    {{ $route.meta.title }}
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'StudioDetail',
  mixins: [PageMixin],
  data() {
    return {

    }
  },
  created() {
    // this.onDetail()
  },
  methods: {
    onDetail() {
      this.$api.course_index({ course_cate_id: this.$route.query.course_cate_id }).then(res => {

      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>

